



































import {
  computed, defineComponent, inject, PropType,
} from '@nuxtjs/composition-api';
import { SfCheckbox, SfScrollable, SfHeading } from '@storefront-ui/vue';
import type { Aggregation } from '~/modules/GraphQL/types';

export default defineComponent({
  components: {
    SfCheckbox,
    SfScrollable,
    SfHeading,
  },
  props: {
    filter: {
      type: Object as PropType<Aggregation>,
      required: true,
    },
  },
  setup() {
    const { isFilterSelected } = inject('UseFiltersProvider');
    const selected = computed(() => ((id: string, optVal: string) => isFilterSelected(id, optVal)));
    return { selected };
  },
});
